<template>
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none">
		<router-link
			to="/"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-jelly-600 dark:hover:text-jelly-300  sm:mx-4 mb-2 sm:py-2 "
			aria-label="Projects"><i :data-feather="'home'" class="w-5 sm:w-8 h-5 sm:h-8"></i></router-link>
		<router-link
			to="/contact"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-jelly-600 dark:hover:text-jelly-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Contact"><i :data-feather="'message-square'" class="w-5 sm:w-8 h-5 sm:h-8"></i></router-link>
	</div>
</template>

<script>
export default { props: ['showModal', 'isOpen'] };
</script>