<template>
  <section class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10">
    <div class="w-full md:w-1/3 text-left">
      <h1 class="font-general-medium text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-400">
        Hey there! I am
      </h1>
      <p class="font-general-semibold mt-2 mb-2 text-4xl sm:text-4xl xl:text-6xl text-center sm:text-left leading-none text-ternary-dark dark:text-primary-light">
        Kadir Aslan.
      </p>
      <div class="text-center sm:text-left">
        <a class="font-general-semibold cursor-default text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-400 dark:text-gray-400">I </a>
        <a class="font-general-semibold cursor-default hover:decoration-4 hover:underline hover:decoration-orange-600 text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-700 dark:text-gray-200">code</a>
        <a class="font-general-semibold cursor-default text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-400 dark:text-gray-400">, </a>
        <a class="font-general-semibold cursor-default hover:decoration-4 hover:underline hover:decoration-jelly-600 text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-700 dark:text-gray-200">design</a>
        <a class="font-general-semibold cursor-default text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-400 dark:text-gray-400"> and </a>
        <a class="font-general-semibold cursor-default hover:decoration-4 hover:underline hover:decoration-green-600 text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-700 dark:text-gray-200">draw</a>
        <a class="font-general-semibold cursor-default text-xl md:text-xl xl:text-2xl text-center sm:text-left text-gray-400 dark:text-gray-400">! </a>
      </div>
      <div class="flex justify-center sm:block">
        <a download="Resume.pdf"
           href="/files/Resume.pdf"
           class="flex justify-center items-center group w-36 sm:w-48 mt-6 mr-6 mb-0 sm:mb-0 sm:mr-1 text-lg border border-jelly-400 dark:border-ternary-dark dark:hover:border-jelly-500 py-2.5 sm:py-3 shadow-lg rounded-lg focus:ring-1 focus:ring-jelly-900 hover:bg-jelly-500 text-gray-500 hover:text-white duration-100"
           aria-label="Download Resume">
          <i data-feather="arrow-down-circle" class="text-jelly-500 group-hover:text-gray-50 ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100 dark:text-gray-50"></i>
          <span class="text-sm text-jelly-500 dark:text-gray-50 group-hover:text-gray-50 sm:text-lg font-general-medium duration-100">Resume</span></a>
        <div class="flex-col sm:flex-row justify-start mt-6 pl-2 border-l-2 border-l--gray-500 hover:border-l-jelly-500 hover:border-l-4 duration-100">
          <p class="text-xs dark:text-gray-50 text-gray-400 font-general-medium duration-100">Also check out my</p>
          <a download="Thesis.pdf"
             href="/files/Thesis.pdf"
             class="flex group text-lg text-ternary-dark duration-100 items-center"
             aria-label="Master's Thesis">
            <i data-feather="arrow-down-right" class="text group-hover:text-jelly-500 ml-0 mr-1 duration-100 dark:text-gray-50"></i>
            <span class="text-sm dark:text-gray-50 sm:text-lg group-hover:text-jelly-500 font-general-medium duration-100">Master's Thesis</span></a>
        </div>
      </div>
    </div>
      <div class="w-full md:w-2/3 text-right float-right">
        <Atropos :active-offset="100" :shadow="false" :highlight="false">
          <img v-if="theme === 'light'"
               src="@/assets/images/light.svg"
               alt="Developer"/>
          <img v-else
               src="@/assets/images/dark.svg"
               alt="Developer"/>
        </Atropos>
      </div>
  </section>
</template>

<script>
import feather from 'feather-icons';
import Atropos from 'atropos/vue';

export default {
  name: 'Home',
  components: {
    Atropos
  },
  data: () => {
    return {
      theme: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('theme') || 'light';
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem('theme') || 'light';
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>


<style scoped></style>
