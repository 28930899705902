<template>
  <link rel='apple-touch-icon' sizes='180x180' href='../public/icons/apple-touch-icon.png'>
  <link rel='icon' type='image/png' sizes='32x32' href='../public/icons/favicon-32x32.png'>
  <link rel='icon' type='image/png' sizes='16x16' href='../public/icons/favicon-16x16.png'>
  <link rel='manifest' href='../public/icons/site.webmanifest'>
  <div :class="appTheme" class="pt-0.5">
    <AppHeader />
    <transition name="fade" mode="out-in"><router-view :theme="appTheme" /></transition>
    <back-to-top visibleoffset="500" right="30px" bottom="20px" class="shadow-lg"><i data-feather="chevron-up"></i></back-to-top>
    <AppFooter />
  </div>
</template>

<script>
import feather from 'feather-icons';
import AppHeader from './components/shared/Header';
import AppFooter from './components/shared/Footer';

export default {
	components: {
		AppHeader,
		AppFooter,
	},
	data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<style>
#app {
	font-family: Inter, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
.vue-back-to-top {
	@apply p-2 bg-jelly-500 hover:bg-jelly-600 text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110;
	border-radius: 50%;
	font-size: 22px;
	line-height: 22px;
}
.fade-enter-active {
	animation: coming 0.4s;
	animation-delay: 0.2s;
	opacity: 0;
}
.fade-leave-active {
	animation: going 0.4s;
}
@keyframes going {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-10px);
		opacity: 0;
	}
}
@keyframes coming {
	from {
		transform: translateX(-10px);
		opacity: 0;
	}
	to {
		transform: translateX(0px);
		opacity: 1;
	}
}
</style>
