<template>
  <div class="flex justify-center items-center text-center">
    <div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
      Developed by
      <a href="https://github.com/kdraslan" target="__blank" class="font-general-medium hover:underline hover:text-jelly-600 dark:hover:text-jelly-300 duration-500">
        {{ author }}
      </a>
      &copy; {{ copyrightDate }}
    </div>
  </div>
</template>

<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			author: 'Kadir Aslan',
		};
	},
};
</script>
