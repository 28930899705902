<template>
  <div class="container mx-auto">
    <AppBanner class="mb-5 sm:mb-8" />
  </div>
</template>

<script>
import AppBanner from '@/components/shared/Banner';

export default {
	name: 'Home',
	components: {
		AppBanner
	},
};
</script>
